import products from "./products"
import modal from "./modal"
import homepage from "./homepage"
import basket from "./basket"
import order from "./order"
import categories from "./categories"
import header from "./header"
import login from "./login"
import user_profile from "./user_profile"

export default {
  products,
  modal,
  homepage,
  basket,
  order,
  header,
  login,
  categories,
  user_profile,
  send: "Send",
  close: "Close",
  apply: "Apply",
  select_currency: "Choose currency"
}