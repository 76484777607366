export default {
  order_not_found: "Order not found.",
  title: "Order detail",
  order_number: "Order number",
  created_at: "Created at",
  order_id: "Order ID",
  shipping_address: "Shipping address",
  billing_address: "Billing address",
  products_title: "Products",
  price_title: "Price",
  taxes: "Taxes",
  tracking_title: "Tracking",
  tracking_no_value_message: "No tracking has been found.",
  shipping_price_title: "Shipping price",
  address_first: "Street",
  address_second: "Street second",
  city: "City",
  country: "Country",
  email: "Email",
  first_name: "First name",
  last_name: "Last name",
  phone: "Phone",
  state: "State",
  zip: "Zip",
  subtotal: "Subtotal",
  total: "Total",
  free: "Free",
  download_invoice_for_order: "Download order PDF"
}
