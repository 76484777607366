export default {
  title: "Contacting",
  store: "store",
  topic_message: "Select a topic",
  visit_store: "Visit store for more information",
  error_message: "You need to select a topic and write a message.",

  subjects: {
    general: "General question",
    product: "Product details",
    shipping: "Shipping info",
    returns: "Returns",
  }
}