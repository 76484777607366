export default {
  title: "Shopping cart",
  quanity_changed_on_server: "There are only {number} products in stock.",
  clear_basket: "Clear shopping cart",
  empty_basket: "Your shopping cart is empty.",
  basket_summary: "Cart summary",
  tax: "Tax",
  free: "Free",
  shipping_price: "Shipping price",
  total: "Total",
  shipping_option: "Shipping option",
  ship_to: "Ship to",
  chosen_method: "Chosen method",
  estimated_delivery_message: "Estimated delivery in {estimated_days} days",
  add_amount_for_better_shipping_discount: "Add {amount} more for {discount_title}",
  off_shipping: "off shipping",
  days_estimate: "~{estimated_days} days",
  shipping_method_default: "Shipping method",
  seller_title: "Seller:",
  unknown_seller: "without domain",
  remove_group_products: "Remove all",
  pay_for_group: "Pay for selected",
  cancel_selection_of_group: "Cancel selection",
  paypal_not_connected: "The owner of the store which owns these products has not configured a payment method yet.",
  contact_message: "Please contact them {link} for further information.",
  subtotal: "Subtotal",
  shopify_buy_button: "Securely checkout order",
  total_price: "Total",
  select_group: "Please select product group to finish the checkout process.",
  better_discount_message: "Add extra {needed_amount} more from this seller for {off_amount}",
  state: "State",
  country: "Country",
  here_link: "HERE",
  view_your_order_message: "Show order detail for: <b>{products}</b> by clicking",
  completed_orders: "Completed orders",
  log_in: "Log in",
  log_in_message: "Log in for better user experience or checkout as a guest:",
  discount_code: "Discount code",
  discount_code_error: "Discount code does not exist, is expired or can not be applied.",
  go_to_basket: "Checkout",
  company_purchase_checkbox: "This is a company purchase",
  VAT_id: "VAT ID",
  business_id: "Business ID",
  company: "Company name"
}
