export default {
  title: "Welcome {name}!",
  account_overview: "Account overview",
  order_history: "Order history",
  settings: "Settings",
  log_into_admin: "Log into admin",
  logout: "Log out",
  delivery_to_title: "Deliver to",
  payment_title: "Payment",
  subtotal: "Subtotal",
  shipping_fee: "Shipping",
  total: "Total",
  tax: "Tax",
  order_number: "Order n.",
  order_status: "Status",
  options: "Options",
  seller: "Seller",
  show_order_detail: "Show order detail",
  unknown_seller: "Unknown seller",
  orders_title: "Orders",
  account_overview_title:  "Account overview",
  profile_title: "Profile",
  name: "Name",
  email: "E-mail",
  shipping_country: "Shipping country",
  state: "State (Only for USA and Canada)",
  phone: "Phone number",
  update: "Update",
  update_succes_message: "Update was successful",
  incorrect_email_format: "Email is not in correct format",
  old_password: "Old password",
  new_password: "New password",
  new_password_again: "New password again",
  password_title: "Password",
  change_password:  "Change",
  wrong_passord: "Wrong old password",
  passwords_dont_match: "You new passwords do not match",
  number_of_projects: "Number of stores",
  number_of_managed_users: "Number of managed users",
  number_of_products_purchased: "Number of purchased products"
}
