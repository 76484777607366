export default {
  add_to_basket: "Add to basket",
  no_products_message: "No products have been found.",
  product_dosnt_exist: "Product you are looking for seems to have disappeared. We are sorry for this inconvenience.",
  out_of_stock: "Out of stock",
  select_variant_message: "Please select a variant",
  go_to_product: "View product",
  go_to_basket: "View basket",
  contact_seller: "Contact seller",
  number_of_sold: "sold",
  origin: "From",
  store_link: "Store",
  clear_filters: "Clear filters",
  popular_products: "Popular products",
  selected_language: "Language / currency",
  buy_now: "Buy now",
  touch_to_zoom: "Touch to zoom",
  hover_to_zoom: "Hover to zoom",
}
